<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="less" scoped>
#app {
  max-width: 750px;
  margin: 0 auto;
  min-height: 100%;
}
</style>
<style lang="less">
html {
  background: #f6f6f6;
}

html,
body {
  min-height: 100%;
  font-size: 14px;
  letter-spacing: 0.7px;
}

</style>
