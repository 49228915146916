import Vue from 'vue'
import Vuex from 'vuex'

import { SET_TOKEN,SET_USER } from '../const.js'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: sessionStorage.getItem('token'),
        user: sessionStorage.getItem('user'),
        // tabbar: sessionStorage.getItem('tabbar'),
    },
    mutations: {
        [SET_TOKEN](state, token) {
            state.token = token
            sessionStorage.setItem('token', token)
        },
        [SET_USER](state, user) {
            state.user = user
            sessionStorage.setItem('user', user)
        },
        // [SET_TABBAR](state, tabbar) {
        //     state.tabbar = tabbar
        //     sessionStorage.setItem('tabbar', tabbar)
        // },
    },
    actions: {},
    modules: {}
})
