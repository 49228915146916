import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [{
  path: '/',
  name: 'base',
  component: () =>
    import('../layout/EmptyLayout.vue'),
  meta: {
    title: '首页',
  },
  redirect: '/',
  children: [
    {
      path: '/',
      name: 'home',
      component: () =>
        import('../views/Home.vue'),
      meta: {
        title: '首页'
      }
    },{
      path: '/auth',
      name: 'auth',
      component: () =>
        import('../views/Auth.vue'),
      meta: {
        title: '认证'
      }
    }
  ]
}]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
